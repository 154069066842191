.generic-button {
    border: none;
    border-radius: 18px;
    box-shadow: 0 2px 8px 0 rgba(38, 88, 165, 0.32);
    background-color: $mid-blue;
    color: $white-two;
    min-width: 240px;
    height: 45px;
    margin: 20px 0 2em 0;

    &.duplicate-button {
        height: 40px;
        margin: 0;
        min-width: 180px;
    }
}

.danger-button {
    border: none;
    border-radius: 18px;
    box-shadow: 0 2px 8px 0 rgba(165, 38, 42, 0.32);
    background-color: $dull-red;
    color: $white-two;
    min-width: 180px;
    height: 40px;
    margin: 20px 0 2em 0;
}

.link-button {
    border: none;
    color: $mid-blue;
    min-width: 240px;
    height: 45px;
    margin: 20px 0 2em 0;
}

.configurator-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
    padding: 0 20px;

    label {
        font-family: 'Roboto-Medium', 'Arial', sans-serif;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
    }

    > div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
    }

    .light-blue {
        background: $very-light-blue;
    }

    .blue {
        background: $roltek-blue;
        color: $white;
    }
    .activeButton span{
        text-decoration: underline !important;
      }

      .activeButton {
        border: 4px solid #1890ff; 
      }
      

      
    
      
}
@media (max-width: 900px) {
    .configurator-buttons {
        padding: 0 8px;
        
        > div {
        gap: 5px;
    }  
}
}
