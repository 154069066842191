html,
body {
    margin: 0;
    padding: 0;
}

.is-locked {
    opacity: 0.6;
    pointer-events: none;
}

.validation-star {
    color: red;
    font-size: 0.8rem;
    margin-left: 0.2rem;
}
