@import "@/assets/scss/style.scss";

.label {
    color: $grey-7;
}

.activeStatus {
    color: $grey-9;
    margin-bottom: 8px;
}

.wrapperActiveStatus {
    margin-bottom: 48px;

    &::v-deep {
        .ant-progress-inner {
            background-color: $grey-4;
        }
    }
}
