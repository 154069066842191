@import "@/assets/scss/style.scss";

.offerHistoryTable {
    overflow-x: auto;
    &::v-deep {
        .ant-table-column-title {
            @extend .u-b2-;
            color: $grey-7;
        }

        .isHighlighted {
            background-color: $grey-2;
        }
    }
}
