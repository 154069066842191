@import "@/assets/scss/style.scss";

.wrapperClientSearch {
    width: 100%;

    .label {
        color: $grey-7;
        margin-bottom: 2px;
    }

    &::v-deep {
        .ant-select {
            width: 100%;
        }
    }
}
