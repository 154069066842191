.co-login,
.c-reset-password {
    .ant-form-item {
        margin-right: 0;
    }

    .ant-form-item-control.has-feedback {
        display: block;
    }

    .ant-form-item-control-wrapper {
        width: 100%;
    }

    .ant-form-item-label {
        margin-bottom: 8px;
        @extend .u-b1;
    }
}

.co-offer-history {
    &__dropdown {
        .ant-dropdown-menu-item {
            &.is-active {
                color: $mid-blue;
                background-color: #1257ab11;
            }

            padding: 8px 12px;
        }
    }
}

.p-datatable {
    .p-datatable-thead > {
        tr {
            th {
                background: $white-two;
                color: $grey-7;
                @extend .u-b2--;

                &.narrow {
                    width: 5em;
                }

                &.medium {
                    width: 9em;
                }

                &.text-right {
                    text-align: right;
                }

                &.text-center {
                    text-align: center;
                }

                &.text-left {
                    text-align: left;
                    padding-left: 20px;
                }

                .p-sortable-column-icon {
                    font-size: 1em;
                }
            }
        }
    }

    .p-datatable-tbody > {
        .p-datatable-dragpoint-bottom {
            td {
                box-shadow: inset 0 -8px 0 0 $dull-red !important;
            }
        }

        .p-datatable-dragpoint-top {
            td {
                box-shadow: inset 0 8px 0 0 $dull-red !important;
            }
        }

        tr {
            td {
                @extend .u-b2;
                color: $grey-9;

                &.text-center {
                    text-align: center;
                }

                &.narrow {
                    width: 5em;
                }

                &.medium {
                    width: 9em;
                }

                &.text-right {
                    text-align: right;
                }

                &.text-left {
                    text-align: left;
                    padding-left: 20px;
                }

                &.rowNumber {
                    @extend .u-b2;
                    color: $grey-7;
                }

                .p-row-toggler {
                    width: 100%;

                    .pi {
                        font-size: 11px;
                    }
                }

                &:first-child {
                    @extend .u-b4;
                    color: $cool-grey;
                }
            }
        }
    }
}


.c-edit-client {
    &__input {
        .ant-input-group-addon {
            border-color: $cloudy-blue;
        }

        .ant-input {
            border-color: $cloudy-blue;
        }

        &.has-errors {
            .ant-input-group-addon {
                border-color: $dull-red;
            }

            .ant-select-selection {
                border-color: $dull-red;
            }

            &.ant-input {
                border-color: $dull-red;
            }
        }
    }

    .ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
        left: initial;
        right: 16px;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 12px 16px;
    }

    .ant-collapse-borderless > .ant-collapse-item {
        border-radius: 18px;
        border-bottom: none;
    }
}

.c-cms-list {
}

.c-material-search {
    &.is-new {
        .c-material-search__input {
            .ant-input {
                background: $white-two;
                border: 1px solid $grey-1;
                border-radius: 2px;
                box-shadow: none;
            }

            .certain-category-icon {
                color: $grey-9;
            }
        }
    }

    &__input {
        .ant-input {
            background: #e8eff9aa;
            border: none;
            border-radius: 10px;
        }
    }

    &__dropdown {
        background: white;
        min-width: 80vw !important;
        padding: 10px;
        border-radius: 4px;
        outline: none;
        -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

        .ant-dropdown-content {
            min-height: 300px;
            max-height: 400px;
            overflow: auto;
            padding: 4px 0;

            > div {
                padding: 5px;
            }
        }

        .c-material-search {
            &__results-group {
                max-width: 60%;
            }

            &__option {
                height: auto;

                &:hover {
                    background-color: $blue-1;
                }

                &.selected {
                    background-color: $blue-1;

                    span,
                    .c-material-search__price {
                        font-weight: 600;
                    }
                }
            }

            &__carousel-container {
                cursor: default !important;
                float: right;
                width: 30%;
                max-width: 394px;
                position: sticky;
                top: 0;
                bottom: 0;
                overflow: visible;
            }

            &__submit {
                &-container {
                    cursor: default !important;
                    max-width: none;
                    margin: 30px 12px 5px;
                    text-align: center;
                    position: sticky;
                    bottom: 5px;
                    clear: both;
                }

                &-button {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}

.ant-table-wrapper.is-materials {
    .ant-table-row-expand-icon {
        display: none;
    }
}

.c-language-picker {
    &__option {
        text-transform: uppercase;
    }
}

.o-section {
    .ant-menu-inline .ant-menu-item,
    .ant-menu-inline .ant-menu-submenu-title {
        width: 100%;
    }
}

.certain-category-search-dropdown {
    .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item.is-special {
        pointer-events: none;
        padding-left: 4px;
    }
}

.co-editable-content-block {
    &__checkbox {
        .ant-checkbox {
            justify-self: center;
            align-self: center;
        }
    }
}

.c-address-form,
.c-contact-person-form {
    .ant-form-item-label {
        line-height: 1.5;

        label {
            @extend .u-b1;
        }
    }
}

.c-price-manipulation-form {
    &__item {
        .ant-form-item-control-wrapper {
            width: 100%;
        }

        .ant-form-item-label {
            margin-right: 8px;
            flex: 1 0 auto;
        }

        .ant-input-number {
            width: 100%;
        }
    }
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: block !important;
}

.vc-chrome {
    box-shadow: none;
    border: 1px solid $grey-4;
    border-radius: 4px;
}

.c-project-history {
    .ant-list-item {
        justify-content: flex-start;
    }
}

.ant-btn {
    &.default {
        color: #40a9ff;
        border-color: #40a9ff;
    }
}

.co-app-table {
    /*.ant-table-selection-column {
        display: none;
    }*/

    &.is-delete-mode {
        .ant-table-selection-column {
            display: table-cell;
        }
    }
}

.ant-btn {
    &.default {
        color: #40a9ff;
        border-color: #40a9ff;
    }
}

.ant-modal-wrap {
    &.no-padding,
    &.contact-popup {
        .ant-modal-content {
            & > .ant-modal-body {
                padding: 0;
            }
        }
    }

    &.hide-overflow {
        overflow: hidden;
    }
}

.c-checklist__row-value {
    &.has-errors {
        input,
        .ant-select-selection {
            border: 1px solid $dull-red;
        }
    }
}

.c-checklist-field-index__number-range-group-delete {
    .anticon {
        font-size: 10px;
        vertical-align: top;
        line-height: 1.6 !important;
    }
}

.ant-tooltip {
    max-width: 600px !important;
    .error &-content {
        .ant-tooltip-arrow::before {
            background-color: red;
        }
        .ant-tooltip-inner {
            background-color: red;
        }
    }
}

.has-two-items {
    .ant-modal-content {
        flex: 1 0 auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .ant-modal-body {
            flex: 1 0 auto;
        }
    }
}

.multiposition {
    background-color: $white-two;
    padding-top: 20px;
    overflow: hidden;

    &--editing {
        overflow: auto;
    }

    .ant-modal {
        top: 0;
        height: 100%;
        padding-bottom: 0;

        .ant-modal-body {
            padding: 0;
            height: 100%;
        }
    }

    .ant-modal-content {
        height: 100%;
        box-shadow: none;
    }
}

.dimensions-wizard-modal {
    background-color: $white-two;
    overflow: hidden;

    .ant-modal {
        top: 0;
        height: 100%;
        padding-bottom: 0;

        .ant-modal-body {
            padding: 0;
            height: calc(100vh - 55px);
        }
    }

    .ant-modal-content {
        height: 100%;
        box-shadow: none;
    }
}

.c-sales-process-form {
    .ant-form-item-label {
        line-height: 20px;
    }
}

.ant-select-dropdown-menu-item {
    &.is-hidden {
        display: none;
    }

    &.is-expired {
        color: $orangey-yellow;
    }
}

.discount-table {
    .ant-table-thead > tr > th {
        background-color: $white-two;
    }
}

.mention {
    background: $blue-1;
    color: $blue-6;
    border-radius: 4px;
    padding: 1px 4px;
    white-space: nowrap;
}

.mention-suggestion {
    color: rgba(0, 0, 0, 0.6);
}

.suggestion-list {
    padding: 0.2rem;
    box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    min-width: 150px;
    @extend .u-b1;

    &__no-results {
        padding: 0.2rem 0.5rem;
    }

    &__item {
        border-radius: 5px;
        padding: 0.2rem 0.5rem;
        margin-bottom: 0.2rem;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }

        &.is-selected,
        &:hover {
            background-color: $blue-1;
            font-weight: bold;
        }

        &.is-empty {
            opacity: 0.5;
        }
    }
}

.tippy-box[data-theme~='dark'] {
    background-color: $white-two;
    padding: 0;
    font-size: 1rem;
    text-align: inherit;
    color: $grey-9;
    border-radius: 5px;
}

.p-datatable-tbody tr.has-errors {
    background-color: $dull-red-transparent;
}

.p-datatable-tbody tr.has-warnings {
    background-color: $grey-7-transparent;
}

.offerHistoryTable {
    .ant-table-thead {
        tr {
            th {
                background: $white-two;
                border: none;
            }
        }
    }

    .ant-table-tbody {
        tr {
            td {
                border: none;
            }
        }
    }
}

.ant-input,
.ant-input-number,
.ant-select-selection {
    border-radius: 2px !important;
}

.productAndMaterialSearchWrapperFooter {
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 12px;

    & > * {
        margin-right: 4px;
        border-radius: 2px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.productSelectorPopupContent {
    display: flex;
    flex-direction: column;

    .popoverButton {
        border-radius: 2px;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.ant-btn {
    border-radius: 2px !important;
}

.ant-menu-inline-collapsed-tooltip,
.ant-dropdown-menu-inline-collapsed-tooltip {
    display: none;
}

.add-product__error-notification {
    margin: 0 0 20px 0;
    background-color: green;

    &::first-of-type {
        background-color: blue;
    }
}

.select-country-wrapper .ant-select-selection {
    padding-left: 55px;
}

.news__table-row {
    cursor: pointer;
}

.ant-dropdown-menu-inline-collapsed-tooltip {
    display: none !important;
}

.ant-select-dropdown-menu-item {
    height: auto;
}

@media (max-width: 481px) {
   .ant-calendar {
        width: fit-content;
    }
}