@import "@/assets/scss/style.scss";

.wrapperClientDetailsAndForm {
    &::v-deep {
        .row {
            display: flex;
            margin-bottom: 24px;
            align-items: flex-start;

            & > *:first-child {
                flex: 1 0 50%;
            }

            & > * {
                flex: 1 1 50%;
                margin-right: 16px;

                &:last-child {
                    margin-right: 0;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
