@import "@/assets/scss/style.scss";

.wrapperTwoRowTextWithIcon {
    display: flex;

    .icon {
        margin-top: 2px;
        margin-right: 16px;
        color: $light-grey;
    }

    .topRow {
        color: $black;
    }

    .bottomRow {
        color: $grey-7;
    }
}
