@import "@/assets/scss/style.scss";

.wrapperOrderedByPicker {
    width: 100%;

    &::v-deep {
        .ant-select {
            width: 100%;
        }
    }
}
