@import "@/assets/scss/style.scss";

.offerHistoryTableActionsWrapper {
    &::v-deep {
        .actions {
            color: $grey-9;
            transform: rotate(90deg);
        }
    }
}
