@import "@/assets/scss/style.scss";

.projectDetailsWrapper {
    display: flex;

    & > * {
        flex: 0 1 33%;
    }

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;

        & > div {
            margin-bottom: 1.5rem;
        }
    }
}
