.o-container {
    display: block;
    margin: 0 auto;

    &--xl {
        max-width: 1020px;
        width: 100%;
    }

    &--md {
        max-width: 600px;
        width: 100%;
    }

    &--sm {
        max-width: 480px;
        width: 100%;
    }
}
