.o-project-section {
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        border-radius: 12px;
        background-color: rgba(255, 255, 255, 0.7);
        pointer-events: none;
        display: none;
    }

    &.is-section-loading {
        pointer-events: none;

        &:before {
            display: block;
        }
    }

    &__loading {
        font-size: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 11;
    }
}
