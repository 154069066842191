$settings-colors: (
    'default': (
        'white': #ffffff,
        'black': #000000,
    ),
    'html': (
        'text': #000000,
        'background': #ffffff,
    ),
    'primary': (
        'white': #ffffff,
        'black': #000000,
        'red': #e11455,
        'red-dark': #90002e,
        'green': #61d47a,
        'green-dark': #007018,
        'grey': #e4dbee,
        'heather': #9787af,
        'violet-blue': #5600c2,
        'pale-grey': #f2eef8,
        'royal-purple': #2f0069,
        'blueberry': #614498,
    ),
    'variations': (
        'black-0': rgba(#000000, 0),
        'black-10': rgba(#000000, 0.1),
        'black-20': rgba(#000000, 0.2),
        'black-30': rgba(#000000, 0.3),
        'black-40': rgba(#000000, 0.4),
        'black-50': rgba(#000000, 0.5),
        'black-60': rgba(#000000, 0.6),
        'black-70': rgba(#000000, 0.7),
        'black-80': rgba(#000000, 0.8),
        'black-90': rgba(#000000, 0.9),
        'black-100': rgba(#000000, 1),
    ),
    'form': (
        'error-red': #fa0000,
        'success-green': #6dd348,
    ),
);

$mid-blue: #2658a5;
$black: #000000;
$slate-grey: #5b6369;
$dark: #273549;
$white: #f9f9f9;
$deep-sky-blue: #007aff;
$white-two: #ffffff;
$cool-grey: #a1aab4;
$nice-blue: #1257ab;
$pale-grey: #f8f9fc;
$light-periwinkle: #d2e0f5;
$pale-rose: #efc9cb;
$pale-yellow: #efe7c9;
$watermelon: #ed5158;
$pale-aqua: #b9f6e3;
$green-teal: #10b180;
$very-light-blue: #e9eef6;
$very-light-blue-two: #e8eff9;
$green-teal-two: #0fc48d;
$camo-green: #624929;
$dull-brown: #886d4b;
$dull-red: #bc3f3f;
$ant-red: #ff4d4f;
$brown-grey: #aeaeae;
$slate-grey-two: #59585b;
$charcoal-grey: #383e42;
$warm-grey: #878581;
$orangey-yellow: #f1b323;
$border-gray: #eff0f5;
$cloudy-blue: #adc0da;
$grey-three: #f5f5f5;
$intro-background: #f0f5ff;
$light-grey: #bfbfbf;
$roltek-blue: #2a5aa4;
$roltek-blue-5: #649fff;
$roltek-blue-6: #386ec2;
$grey-0: #f0f0f0;
$grey-1: #d9d9d9;
$grey-2: #fafafa;
$blue-1: #e6f7ff;
$grey-4: #e8e8e8;
$grey-9: #262626;
$grey-8: #595959;
$grey-7: #8c8c8c;
$gold-1: #fffbe6;
$green-1: #f6ffed;
$green-3: #b7eb8f;
$blue-6: #1890ff;
$blue-4: #82b2ff;
$grey-bg: #f1f1f1;
$red-6: #f5222d;
$green-5: #73d13d;
$dull-red-transparent: #bc3f3f75;
$grey-7-transparent: rgba(17, 33, 60, 0.03);
$multiposition-background: #f3f4f5;
$sidebar-background: #f3f4f6;
$backdrop: rgba(0, 0, 0, 0.4);

$shadow-button-blue: 0 2px 8px 0 rgba(38, 88, 165, 0.32);
$shadow-button-0: 0 2px 30px 0 rgba(45, 0, 101, 0);

$shadow-negative-1: 0 2px 25px 0 rgba(102, 0, 32, 0.5);
$shadow-negative-0: 0 2px 25px 0 rgba(102, 0, 32, 0);

$shadow-positive-1: 0 2px 25px 0 rgba(0, 128, 27, 0.5);
$shadow-positive-0: 0 2px 25px 0 rgba(0, 128, 27, 0);

$shadow-neutral-1: 0 2px 25px 0 rgba(183, 164, 205, 0.5);
$shadow-neutral-0: 0 2px 25px 0 rgba(183, 164, 205, 0);

$shadow-legend-0: 0 2px 20px 0 rgba(26, 0, 59, 0);
$shadow-legend: 0 2px 20px 0 rgba(26, 0, 59, 0.3);

$shadow-account-form: 0 2px 30px 0 #f4f0f9;

$shadow-modal: 0 2px 50px 0 rgba(12, 0, 27, 0.45);

$shadow-account-settings: 0 2px 40px 0 rgba(25, 9, 48, 0.15);

$shadow-navigation: 0 2px 80px 0 rgba(25, 9, 48, 0.35);
