@import "@/assets/scss/style.scss";

.wrapperOfferHistory {
    background-color: $white-two;
    border-radius: 12px;
    width: 100%;
    position: relative;

    p {
        text-align: center;
    }
}

.hasPadding {
    padding: 30px;
}

.title {
    padding-bottom: 10px;
}
