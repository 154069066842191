@import "@/assets/scss/style.scss";

@media (max-width: 600px) {
    .wrapperClientDetailsAndForm .wrapperClientDetails > .row {
        flex-direction: column;
        align-items: flex-start;

        & > div {
            margin-bottom: 1.5rem;
        }
    }
}

::v-deep .wrapperTwoRowWithTag.isLarge .bottomRow {
    text-align: left;
    max-width: 200px;
    word-break: break-all;
}
