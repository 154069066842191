@import "@/assets/scss/style.scss";

.titleWrapper {
    display: flex;
    justify-content: space-between;

    &.success {
        h4 {
            color: $green-teal;
        }
    }

    &.error {
        h4 {
            color: $dull-red;
        }
    }
}

.error {
    margin: 0 0 4px;

    &.warning {
        color: $orangey-yellow;
    }

    &.abort {
        color: $dull-red;
    }
}

.pricesWrapper {
    text-align: right;
}

.is-bold {
    font-weight: bold;
}

.submitButton {
    margin-left: 4px;
}

.actionsWrapper {
    text-align: right;
}
