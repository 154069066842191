html {

    @include font-default;
    @include default-font-size;
    color: getColor('text', 'html');

    &.is-locked {
        top: 0;
        left: 0;
        right: 0;
        position: fixed;
    }

}

html, body {
    width: 100%;
    height: 100%;
}

.content-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.c-footer {
    flex: 1 0 auto;
}
