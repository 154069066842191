@import "@/assets/scss/style.scss";

.actionWrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    @media (max-width: 1220px) {
        flex-flow: wrap;
        justify-content: flex-start;

        & > * {
            margin-bottom: 0.7rem;
        }
    }

    & > div {
        margin-bottom: 1.5rem;
    }

    & > * {
        margin-right: 4px;

        &:last-child {
            margin-right: 0;
        }
    }
}
