// RESET MARGINS
.u-a4,
.u-a3,
.u-a2--bold,
.u-a2,
.u-a1--bold,
.u-a1,
.u-b0,
.u-b1,
.u-b2--bold,
.u-b2--,
.u-b2-,
.u-b2,
.u-b3--bold,
.u-b3--bold-,
.u-b3-,
.u-b3,
.u-b4 {
    margin-top: 0;
    margin-bottom: 0;
}

.u-a4 {
    font-family: $font-regular;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.36px;
}

.u-a3 {
    font-family: $font-regular;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 0.35px;
}

.u-a2--bold {
    font-family: $font-bold;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.38px;
}

.u-a2 {
    font-family: $font-regular;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.38px;
}

.u-a1--bold {
    font-family: $font-bold;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.41px;
}

.u-a1 {
    font-family: $font-regular;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.41px;
}

.u-b0 {
    font-family: $font-bold;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.32px;
}

.u-b0- {
    font-family: $font-regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.32px;
}

.u-b1 {
    font-family: $font-regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.24px;
}

.u-b2--bold {
    font-family: $font-bold;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.08px;
}

.u-b2 {
    font-family: $font-regular;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.08px;
}

.u-b2- {
    font-family: $font-medium;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
}

.u-b2-- {
    font-family: $font-regular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
}

.u-b3--bold {
    font-family: $font-bold;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 0.07px;
}

.u-b3--bold- {
    font-family: $font-bold;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 0.06px;
}

.u-b3 {
    font-family: $font-medium;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 0.07px;
}

.u-b3- {
    font-family: $font-regular;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 0.07px;
}

.u-b4 {
    font-family: $font-regular;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
}

.--uppercase {
    text-transform: uppercase;
}

.--capitalize {
    text-transform: capitalize;
}
