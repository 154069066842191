.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

//
//.fade-enter-active, .fade-leave-active {
//    transition: opacity 0.3s;
//
//        .transition-content {
//            transition: transform 0.3s;
//        }
//}
//
//.fade-enter, .fade-leave-to {
//    opacity: 0;
//
//        .transition-content  {
//            transform: translateX(50px);
//        }
//}
